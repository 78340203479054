import React, { useState } from "react"
import AlertTitle from "@mui/material/AlertTitle"
import Alert from "@mui/material/Alert"
import style from "./../landingPage.module.css"
import phoneFrame from "./../assets/iPhoneFrame.png"
import { Preview_tool } from "../../../api/preview_tool"
import { PhoneViewModel } from "../../../models/PhoneViewTool"
import axios, { AxiosError } from "axios"
import Loader from "../../../components/base/Loader"
import Input from "../../../components/base/Input"
import { validateUrl } from "../../../pages/helpers"
import White_Base64_Screenshot from "../../../components/assets/blank_screenshot_base64"

function Customizer() {
    const [link, setLink] = useState("")
    const [loading, setLoading] = useState(false)
    const [preViewError, setPreViewError] = useState(false)
    const [screenshot, setScreenshot] = useState(White_Base64_Screenshot)
    const [previewLinkError, setPreviewLinkError] = useState<FieldStatus>({
        displayError: false,
        errorMessage: "Please enter a valid url",
    })

    interface FieldStatus {
        displayError: boolean
        errorMessage: string
    }

    const errorMessage = (status: FieldStatus) => {
        if (status.displayError) {
            return <p className={style.errorText}>{status.errorMessage}</p>
        }
        return <br />
    }

    const validateData = () => {
        let linkValid = validateUrl(link)
        setPreviewLinkError({ ...previewLinkError, displayError: !linkValid })

        return linkValid
    }

    function submitUrl(data: PhoneViewModel) {
        Preview_tool(data)
            .then((result) => {
                if (
                    Object.prototype.toString.call(result) !== "[object Object]" ||
                    axios.isAxiosError(result) ||
                    result === undefined
                ) {
                } else {
                    setScreenshot(result.iphone_base64)
                    setLoading(false)
                }
            })
            .catch((e: AxiosError) => {
                if (e.response?.status == 400) {
                    setPreviewLinkError({ ...previewLinkError, displayError: true })
                    setLoading(false)
                } else setPreViewError(true)
                console.log("Error loading preview.")
            })
    }

    const Preview = () => {
        if (preViewError) {
            return (
                <div>
                    <Alert severity="error">
                        <AlertTitle>Showing the preview failed. </AlertTitle>
                        Our team has been informed. Sorry for the inconvenience. Please try again
                        later.{" "}
                    </Alert>
                </div>
            )
        } else if (loading) {
            return <Loader className={"mt-40 ml-40"} />
        }
        return (
            <div>
                <img className={style.demoFrame} src={phoneFrame} alt=""></img>
                <img className="mt-8 ml-4" src={`data:image/jpeg;base64,${screenshot}`} alt="" />
            </div>
        )
    }

    const onFormSubmit = (e: any) => {
        e.preventDefault()
        setPreViewError(false)
        if (validateData()) {
            setLoading(true)
            submitUrl({ url: link })
        }
    }

    return (
        <div className="px-4 flex items-center justify-center flex-col md:flex-row pt-28">
            <div className="flex items-center justify-center flex-col md:max-w-lg">
                <div>
                <h1 className="h2-blue mb-4">Preview it right here, right now</h1>
                <p className="p mb-2">See how your application will look like!</p>
                <p className="p ">
                    Of course, the final App may differ depending on your individual styling
                    settings.
                </p>
                </div>
                <div className="mb-12">
                    <Preview />
                </div>
            </div>

            <form onSubmit={onFormSubmit} className="w-full flex-1 md:max-w-lg">
                <p className="p mb-2">Enter your shop URL here:</p>
                <Input
                    type="text"
                    hasError={previewLinkError.displayError}
                    errorText={previewLinkError.errorMessage}
                    className="w-full"
                    onChange={(event: any) => {
                        setLink(event.target.value)
                        setPreviewLinkError({ ...previewLinkError, displayError: false })
                    }}
                />
                <button
                    type="submit"
                    className={
                        "mt-8 mb-6 bg-primary w-full py-4 text-white rounded-lg text-xl font-medium"
                    }
                    onClick={onFormSubmit}
                >
                    Show my app
                </button>
            </form>
        </div>
    )
}

export default Customizer

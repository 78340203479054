import axios, { AxiosError, AxiosResponse, AxiosPromise } from "axios"
import { IAPIError, HTTP_METHOD, json_header, unwrapAxiosError } from "./helpers"

import CONSTS from "../CONSTS"
import {
    PhoneViewModel,
    PhoneViewModelResp,
    ValidatePhoneViewModel,
    ValidatePhoneViewRespModel,
} from "../models/PhoneViewTool"

export const Preview_tool = async (
    data: PhoneViewModel
): Promise<PhoneViewModelResp | AxiosError> => {
    return new Promise<PhoneViewModelResp | AxiosError>((resolve, reject) => {
        let valid = ValidatePhoneViewModel(data)
        if (!valid.ok) {
            return reject({
                raisedBy: "signup",
                error: `Field ${valid.field} is ${valid.reason}`,
                code: 0,
            })
        }

        return axios({
            method: HTTP_METHOD.Get,
            url: "/misc/phonepreview",
            params: { url: data.url },
            baseURL: CONSTS.BACKEND_BASE_URL,
            validateStatus: (status) => {
                return status === 200
            },
        })
            .then((res: AxiosResponse) => {
                let data = res.data

                if (data === undefined) {
                    throw "No image received."
                }

                let valid = ValidatePhoneViewRespModel(data)
                if (!valid.ok) {
                    throw "The returned object was invalid." + valid.reason
                }

                resolve(data)
            })
            .catch((e: AxiosError) => {
                return reject(e)
            })
    })
}

import React from "react"
import Footer from "../../components/footer/footer"
import Customizer from "./components/customizer"
import Datadriven from "./components/datadriven"
import Header from "./components/header/header"
import Pricing from "./components/pricing/pricing"
import SellingPoints from "./components/sellingPoints/sellingPoints"
import SimpleSteps from "./components/simpleSteps"
import style from "./landingPage.module.css"

function LandingPage() {
    return (
        <div className={style.lpWrap1}>
            <Header />
            <SellingPoints />
            <Customizer />

            <Datadriven />
            <Pricing />
            <SimpleSteps />
            <Footer />
        </div>
    )
}

export default LandingPage

import React, { useState } from "react"
import { CustomerSignupModel } from "../../models/CustomerSignup"
import Register from "./components/Register"
import Response from "./components/Response"

export enum SubmittedState {
    SUBMITTED = "submitted",
    NOT_SUBMITTED = "notSubmitted",
    FAILED = "failed",
    DUPLICATE = "duplicate",
}

const Order = () => {
    const [form, setForm] = useState<CustomerSignupModel>({
        contact_email: "",
        website: "",
        language: window.navigator.language,
    })

    // @NOTE - these are used through 'setSubmittedState'
    const [submitted, setSubmitted] = useState<SubmittedState>(SubmittedState.NOT_SUBMITTED)

    switch (submitted) {
        case SubmittedState.NOT_SUBMITTED: {
            return (
                <Register onSubmit={setSubmitted} form={form} setForm={setForm} />
            )
        }
        case SubmittedState.FAILED: 
        case SubmittedState.DUPLICATE:
        case SubmittedState.SUBMITTED: {
            return (
                <Response onSubmit={setSubmitted} submitState={submitted}/>
            )
        }
        default: {
            // If we have some weird state (from changing how this page was set up) we reset it to 'not submitted'
            setSubmitted(SubmittedState.NOT_SUBMITTED)
            return <Response onSubmit={setSubmitted} submitState={SubmittedState.FAILED}/> // This should never happen, Need this in order for Order to be a valid JSX.Element
        }
    }
}

export default Order

import { validate, ModelValidateResult } from "../api/validate"

export interface CustomerSignupModel {
    contact_email: string
    website: string
    language: string
}

const customerSignupModelConstraints = [
    { field: "contact_email", type: "string" },
    { field: "website", type: "string" },
    { field: "language", type: "string" },
]

export const ValidateCustomerSignupModel = (n: any): ModelValidateResult<CustomerSignupModel> => {
    return validate<CustomerSignupModel>(n, customerSignupModelConstraints)
}

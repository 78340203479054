import React, { useState } from "react"
import { CustomerSignupModel } from "../../../models/CustomerSignup"
import { Signup } from "../../../api/signup"
import { validateEmail, validateUrl } from "../../helpers"
import { IAPIError } from "~/api/helpers"
import Paper from "../../../components/base/Paper"
import Input from "../../../components/base/Input"
import FlatDesign from "../../../components/assets/flatdesign_cellphone.svg"
import { SubmittedState } from "../order"

type PropTypes = {
    onSubmit: (state: SubmittedState) => void
    form: CustomerSignupModel
    setForm: (form: CustomerSignupModel) => void
}

interface IFormErrors {
    email: string
    url: string
}

const NotSubmitted = ({ form, setForm, onSubmit }: PropTypes) => {
    const [error, setError] = useState<IFormErrors>({ email: "", url: "" })

    const emailError = "Please enter a valid email address"
    const webSiteError = "Please enter a valid website address"

    const validateData = () => {
        //Checks fields if not empty, checks all if force is true
        let cm_valid = false
        let w_valid = false

        cm_valid = validateInput("email", form.contact_email)
        w_valid = validateInput("url", form.website)

        setError({ email: cm_valid ? "" : emailError, url: w_valid ? "" : webSiteError })

        return cm_valid && w_valid
    }

    const validateInput = (key: keyof IFormErrors, value: string) => {
        let containsError = false

        if (key === "email") {
            containsError = !validateEmail(value)
            setError({ ...error, email: containsError ? emailError : "" })
        } else if (key === "url") {
            containsError = !validateUrl(value)
            setError({ ...error, url: containsError ? webSiteError : "" })
        }
        return !containsError
    }

    const updateData = (key: keyof CustomerSignupModel, value: string) => {
        let updated = form
        updated[key] = value

        setForm(updated)
    }
    async function submitSignup(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault()
        if (!validateData()) {
            return
        }

        // For the regex to pass, we can safely add https to an url since an invalid url without it won't be validated.
        // @NOTE - this is required by the backend's validator.
        if (!form.website.startsWith("https://") && !form.website.startsWith("http://")) {
            form.website = "https://" + form.website
        }

        Signup(form)
            .then(() => {
                onSubmit(SubmittedState.SUBMITTED)
            })
            .catch((e: IAPIError) => {
                if (e.code === 409) {
                    onSubmit(SubmittedState.DUPLICATE)
                } else {
                    onSubmit(SubmittedState.FAILED)
                }
            })
            .finally(() => {
                setForm({
                    contact_email: "",
                    website: "",
                    language: window.navigator.language,
                })
            })
    }

    return (
        <div className="pt-2 md:pt-0 md:h-screen flex justify-center bg-slate-50 items-center">
            <Paper className="max-w-6xl flex flex-col md:flex-row">
                <div className="flex-none w-full md:w-4/12 bg-primary md:rounded-tl-lg md:rounded-bl-lg flex pt-4 md:pt-40 px-5 flex-col items-center">
                    <h1 className="text-white font-semibold text-3xl mb-5 md:mb-28">
                        Launch your business mobile now! <br/> 30 days trial, no strings attached.
                    </h1>
                    <img
                        className="invisible md:visible w-0 md:w-9/12"
                        alt="flatdesign og man designing application"
                        src={FlatDesign}
                    />
                </div>
                <div className="grow p-4 md:p-40">
                    <h1 className="h1">Register</h1>
                    <p className="p">
                        Fill out the form and we will contact you. If your website is compatible we
                        will be able to provide you with a demo version of the app that you can
                        preview on your phone.
                    </p>
                    <form onSubmit={submitSignup}>
                        <Input
                            label="Email:"
                            type={"email"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                updateData("contact_email", e.target.value)
                            }
                            className={"pt-7"}
                            errorText={error.email}
                            hasError={error.email !== ""}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                validateInput("email", e.target.value)
                            }
                        />

                        <Input
                            label="Link to your webshop:"
                            type={"text"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                updateData("website", e.target.value)
                            }
                            className={`mt-7`}
                            errorText={error.url}
                            hasError={error.url !== ""}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                validateInput("url", e.target.value)
                            }
                        />
                        <button
                            type="submit"
                            className={
                                "mt-8 mb-6 bg-primary w-full py-4 text-white rounded-lg text-xl font-medium"
                            }
                        >
                            Register
                        </button>
                    </form>
                </div>
            </Paper>
        </div>
    )
}

export default NotSubmitted

import React from "react"
import "./common.css"
import "./index.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navbar from "./components/navbar/navbar"
import LandingPage from "./pages/landingPage/landingPage"
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy"
import Order from "./pages/order/order"
import CookieBanner from "./components/cookieBanner/CookieBanner"

function App() {
    return (
        <Router>
            <Navbar>
                <CookieBanner />
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/order" element={<Order />} />
                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                </Routes>
            </Navbar>
        </Router>
    )
}

export default App

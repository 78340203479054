import * as React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { InputVariants } from "~/components/base/Input"
import style from "~/components/base/Input/Input.module.css"

const Loader = ({ ...rest }) => {
    return (
        <Box sx={{ display: "flex" }} {...rest}>
            <CircularProgress />
        </Box>
    )
}

export default Loader
